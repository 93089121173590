.App {
  display:flex;
  flex-flow:column;
  align-items:center;
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body{
  background-color:#333333;
  color:white;
}

.navigation{
  height:50px;
  display:flex;
  flex-flow:row;
  justify-content: space-between;
  background-color:black;
  color:white;
  font-size:24px;
  padding:15px;
}

.nav-login-actions{
  display:flex;
  flex-flow:row;
}

.link-button{
  padding:5px;
  padding-left:10px;
  padding-right:10px;
  margin-right:10px;
  border: solid white 2px;
  border-radius:5px;
  user-select: none;
  cursor:pointer;
}

.link-button.share{
  background-color:#00948F;
}

.link-button.share:hover{
  filter:brightness(1.2);
}

.link-button.mod:hover{
  filter:brightness(1.2);
}

.link-button.mod{
  background-color:#00950F;
}

.stream{
  position:relative;
  padding-top:10px;
  display:flex;
  flex-flow:column;
  justify-content: space-around;
  align-items:center;
  width:100%;
  height:80vh;
  background-color:#333333;
}

.twitch-embed{
  width:inherit;
  height:inherit;
}

.plugin-ui{
  position:absolute;
  bottom:0;
  left:0;
  width:50%;
  display:flex;
  flex-flow:column;
}

.plugin-grid{
  z-index:1;
  padding:15px 0px 15px 0px;
  transition:all 0.5s;
  clip-path: inset(0 0 0 0);
  display:grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 10px;
  width:inherit;
  background-color:#9100006b
}

.plugin-grid.hidden{
  clip-path: inset(100% 0 0 0);
  
}

.plugin-icon{
  display:flex;
  flex-flow:column;
  align-items:center;
  font-size:24px;
}

.plugin-header{
  position:relative;
  width:25%;
  margin-top:10px;
  height:50px;
  font-size:24px;
  text-align: center;
  background-color:#0077ff;
}
@media screen and (max-width:600px) {
  .plugin-header{
    width:100%;
  }
  .plugin-ui{
    width:100%;
  }
}