  .overlay{
    background-color: rgba(0, 0, 0, 0.5);
    border: solid white 2px;
    position: fixed;
    z-index: 10;
    padding-right:10px;
    padding-left:10px;
    padding-bottom: 20px;
    border-radius:10px;
  }

  .overlay iframe{
    z-index: -1;
  }
  
  .resize-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: -10px;
    right: -10px;
    background-color: #eee;
    border: 1px solid #ccc;
    cursor: se-resize;
  }

  .overlay-header{
    display:flex;
    flex-flow:row;
    align-items: center;
    justify-content: space-between;
    user-select:none;
  }

  .overlay__controls {
    display: flex;
    justify-content: space-between;
    background-color: rgba(0, 0, 0, 0.5);
    align-items: center;
  }

  .overlay__controls_bottom{
    display:flex;
    justify-content: flex-end;
  }
  
  .overlay__close:after {
    display: inline-block;
    color:white;
    content: "\00d7";
  }
  
  .overlay__close {
    border: none;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 30px;
    cursor: pointer;
  }

  .overlay .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    background-color: #eee;
    border: 1px solid #ccc;
    cursor: se-resize;
  }

  .overlay__controls_bottom{
    background-color: rgba(0, 0, 0, 0.5);
  }